@media (max-width: 768px) {
    .admin-profile-card .details-page-content {
        width: 100%;
    }

    .admin-profile {
        width: 98% !important;
        max-width: 98% !important;
        min-width: 98% !important;
        padding: 20px !important;
    }

    .details-data-line-title {
        width: 120px !important;
        min-width: 120px !important;
        max-width: 120px !important;
    }
}

@media (max-width: 500px) {
    .details-data-line-title {
        width: 80px !important;
        min-width: 80px !important;
        max-width: 80px !important;
    }
}
