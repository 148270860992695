.admin-manage-gym-modal {
    width: 1080px !important;
    min-width: 1080px !important;
    max-width: 1080px !important;
}

.admin-manage-gym-modal .admin-section {
    height: 530px;
    overflow-y: scroll
}

@media (max-width: 1080px) {
    .admin-manage-gym-modal {
        width: 98% !important;
        min-width: 98% !important;
        max-width: 98% !important;
    }
}