.details-page-content{
    align-items: center;
}

.details-page-content .details-page-section{
    padding: 20px 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: var(--white-color);
    width: 704px;
    border-radius: 10px;
    box-shadow: 0 0 3px var(--light-shadow-color);
    flex-direction: column;
}

.details-page-section .details-img-container{
    position: relative;
}

.details-page-section .details-img{
    width: 254px;
    height: 254px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--table-header-color);
    border-radius: 50%;
    overflow: hidden;
}

.details-page-section .details-img-container .details-cam-btn.en{
    position: absolute;
    top: 74%;
    right: 5%;
}

.details-page-section .details-img-container .details-cam-btn.ar{
    position: absolute;
    top: 74%;
    right: 5%;
}

.details-img-container #file-uploader{
    display: none;
}

.details-page-section .details-gym-info{
    margin-inline-end: auto;
    width: 100%;
}

.details-gym-info .details-info-section{
    padding: 20px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.details-gym-info .details-info-section:not(:last-child){
    border-bottom: 1px solid var(--border-color);
}

.details-gym-info .details-info-section .details-info-title{
    font-weight: bold;
    text-transform: uppercase;
}

.details-gym-info .details-info-section .details-info-data{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.details-gym-info .details-info-section .details-info-data .info-badge{
    text-align: center;
    padding: 10px;
    background: var(--table-header-color);
    border: 1px solid var(--inactive-white-color);
    border-radius: 10px;
}

.details-info-data .details-data-line{
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.details-info-data .details-data-line .details-data-line-title{
    color: var(--label-color);
    min-width: 180px;
    max-width: 180px;
}

.details-info-data .details-data-line .details-data-line-data{
    color: var(--primary-black-color);
    flex: auto;
    display: flex;
}

.details-info-data .details-data-line .details-data-line-data{
    justify-content: space-between;
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
}

.details-info-data .details-data-line .details-data-line-data span{
    margin-inline-end: 10px;
}

.details-info-data .details-data-line .details-data-line-control{
    color: var(--main-color);
    margin-inline-start: auto;
}

.details-info-data .details-data-line .details-data-line-control:disabled{
    opacity: 0.7;
    cursor: auto;
}

@media(max-width: 668) {
    .details-page-section{
        width: 100;
    }
}