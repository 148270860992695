.default-img {
    padding: 50px;
}

.page-section .table-section{
    background: var(--white-color);
    box-shadow: 0px 0px 3px var(--light-shadow-color);
    border-radius: 10px;
}

.table-section .table-header{
    padding: 10px 20px;
    border-radius: 10px 10px 0 0;
    background: var(--table-header-color);
    border-bottom: 1px solid var(--border-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.table-section .table-header .btn{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.table-section .table-container{
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

@media(max-width: 615px){
    .table-section .table-container{
        justify-content: center;
    }
}

.table-section .table-container .account-item{
    padding: 20px;
    background: var(--table-header-color);
    border: 1px solid var(--inactive-white-color);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    max-width: 244px;
    /* word-break: break-word; */
}

.account-item .img{
    width: 204px;
    height: 204px;
    margin-bottom: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--inactive-white-color);
}

.account-item .name-section{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.account-item .name-section .name{
    font-weight: bold;
    font-size: 0.875rem;
    color: var(--primary-black-color);
}

.account-item .name-section .controls{
    display: flex;
    gap: 22px;
}

.account-item .content{
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 8px;
    font-size: 0.875rem;
}

.account-item .content .title{
    color: var(--label-color);
}

.account-item .content .data{
    color: var(--primary-black-color);
}