.subscription-card ,.subscription-card-active {
    display: flex;
    flex-direction: row;
    background-color: #F8F8F8;
    margin-bottom: 10px;
    border-radius: 10px;
    border: 1px solid #dddddd;
}

.subscription-details ,.subscription-details-active {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 35%;
    gap: 10px;
    padding: 20px;
}

.subscription-features {
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 65%;
    padding: 20px;
}

.subscription-details .title {
    color: #807F7F;
}

.subscription-details .price {
    color: #191919;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 17px;
}

.subscription-details .price span {
    font-weight: normal;
    font-size: 14px;
}

.subscription-features li img {
    width: 15px;
    margin-right: 5px;
}

.subscription-details-active {
    background-color: #191919;
    color: white;
}

.subscription-card-active {
    border: 2px solid #191919;
}

.subscription-card:hover ,.subscription-card-active:hover {
    cursor: pointer;
}

@media(max-width: 576px) {
    .subscription-card ,.subscription-card-active {
        flex-direction: column;
    }
    
    .subscription-features ,.subscription-details ,.subscription-details-active {
        width: 100% !important;
        margin-bottom: 10px;
    }
}